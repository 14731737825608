/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
    position: relative;
    top: 0 !important;
    left: 0 !important;
}

:root {
    --colorCodeSelfTriage: #99C8D6;
    --colorCodeNps: #99C8D6;
    --colorCodePatientCenter: #67C4E0;
    --colorCodeTelemedicine: #85C443;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: #F8F8F8;
    color: #212121;
}

.back-header {
    color: white;
    position: absolute;
    right: 25px;
    display: inline-block;
    text-decoration: none;
    font-weight: 500;
}

.back-header mat-icon {
    vertical-align: middle;
    margin-right: 2px;
    font-size: 21px;
}

/*********************************MATERIAL INPUT********************************************/
.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #85C443 !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
    border-left: unset;
}

mat-spinner {
    width: 20px !important;
    height: 20px !important;
    margin-left: 15px;
}

mat-spinner svg {
    width: 20px !important;
    height: 20px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    color: #85C443;
}

.mdc-notched-outline__leading,
.mdc-notched-outline__notch,
.mdc-notched-outline__trailing {
    border-color: #85C443 !important;
}

/* Font color */
input.mat-input-element {
    color: #212121;
}

.filters .mat-form-field-appearance-outline .mat-form-field-outline {
    color: #A6A6A629;
}

mat-form-field {
    width: 100%;
}

.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-selected-focus-icon-color: #85C443 !important;
    --mdc-checkbox-selected-hover-icon-color: #85C443 !important;
    --mdc-checkbox-selected-icon-color: #85C443 !important;
    --mdc-checkbox-selected-pressed-icon-color: #85C443 !important;
}

.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-focus-icon-color: #85C443 !important;
    --mdc-checkbox-selected-hover-icon-color: #85C443 !important;
    --mdc-checkbox-selected-icon-color: #85C443 !important;
    --mdc-checkbox-selected-pressed-icon-color: #85C443 !important;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
    background: #85C443;
}

.mdc-checkbox-checked.mdc-accent .mdc-checkbox__background,
.mat-mdc-checkbox.mat-accent.mdc-checkbox--selected.mdc-checkbox__ripple {
    background-color: #85C443 !important;
    background: #85C443 !important;
}

.mat-mdc-checkbox.mdc-checkbox:hover.mdc-checkbox__native-control:enabled:checked.mdc-checkbox__background {
    background-color: #85C443 !important;
    border-color: #85C443 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #85C443 !important;
}

.mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: #85C443;
    --mdc-switch-selected-handle-color: #85C443;
    --mdc-switch-selected-hover-state-layer-color: #85C443;
    --mdc-switch-selected-pressed-state-layer-color: #85C443;
    --mdc-switch-selected-focus-handle-color: #85C443;
    --mdc-switch-selected-hover-handle-color: #85C443;
    --mdc-switch-selected-pressed-handle-color: #85C443;
    --mdc-switch-selected-focus-track-color: #cee7b3;
    --mdc-switch-selected-hover-track-color: #cee7b3;
    --mdc-switch-selected-pressed-track-color: #cee7b3;
    --mdc-switch-selected-track-color: #cee7b3;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #85C443 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #cee7b3 !important;
}

.mat-pseudo-checkbox.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox.mat-pseudo-checkbox-indeterminate {
    background: #85C443;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #85C443;
}

.loading-list .mat-progress-spinner circle,
.loading-list .mat-spinner circle {
    stroke: #85C443;
}

.outline-spinner input {
    display: inline-block;
    width: calc(100% - 12px);
}

.outline-spinner mat-spinner {
    width: 12px !important;
    height: 12px !important;
    display: inline-block;
}

.outline-spinner mat-spinner svg {
    width: 12px !important;
    height: 12px !important;
}

.outline-spinner .mat-progress-spinner circle,
.outline-spinner .mat-spinner circle {
    stroke: #426221 !important;
}

.ckeck-privacy-policy .mat-checkbox-frame {
    box-shadow: 0 0 3px #077A20;
}

/*********************************END MATERIAL INPUT********************************************/

/*********************************BUTTONS********************************************/

button {
    margin-bottom: 10px;
}

.mat-mdc-unelevated-button.mat-primary {
    --mdc-filled-button-container-color: #85C443;
    --mdc-filled-button-label-text-color: #fff;
    width: 100%;
}


.btn-primary {
    background-color: #85C443 !important;
    color: white !important;
    margin-bottom: 10px !important;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #4f7528 !important;
    color: white !important;
}

.btn-primary.mat-flat-button.mat-accent,
.btn-primary.mat-raised-button.mat-accent,
.btn-primary.mat-fab.mat-accent,
.btn-primary.mat-mini-fab.mat-accent {
    background-color: #85C443 !important;
}

.btn-primary .mat-progress-spinner circle,
.btn-primary .mat-spinner circle {
    stroke: white !important;
}

.btn-primary mat-spinner {
    margin: auto !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}

.btn-primary.block {
    display: block !important;
    width: 100% !important;
}

.btn-primary.add-user {
    margin-bottom: 30px !important;
}

.btn-primary:disabled {
    background-color: #e6e6fa !important;
}

.btn-secundary {
    color: #85C443 !important;
    background-color: white !important;
    border-color: #85C443 !important;
    margin-bottom: 10px !important;
    border: 1px solid !important;
}

.btn-secundary.block {
    display: block !important;
    width: 100% !important;
}

.btn-block {
    width: 100% !important;
}

.back-link {
    color: #212121;
    text-decoration: none;
    display: none;
    margin-bottom: 10px;
}

.back-link:hover,
.back-link:focus {
    color: #212121;
    text-decoration: none;
}

.back-link mat-icon {
    color: #85C443;
    vertical-align: bottom;
    font-size: 20px;
}

.mat-mdc-progress-spinner.mat-spinner-btn .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: white !important;
}


/*******************************************RADIO*****************************************/
.mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #85C443;
    --mdc-radio-selected-hover-icon-color: #85C443;
    --mdc-radio-selected-icon-color: #85C443;
    --mdc-radio-selected-pressed-icon-color: #85C443;
    --mat-mdc-radio-checked-ripple-color: #85C443;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #85C443;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #85C443;
}

.mat-radio-label-content {
    padding-left: 4px !important;
    padding-right: 14px !important;
}

.mat-radio-button {
    margin-bottom: 15px;
}

.copy-to-clipboard {
    border: none;
    color: #85C443;
    background-color: transparent;
    line-height: unset;
}

.copy-to-clipboard:hover {
    opacity: 0.85;
}

.copy-icons {
    font-size: 16px;
}

/********************************************END RADIO************************************/


/*********************************END BUTTONS********************************************/




/*********************************LAYOUT********************************************/
.container-body {
    padding: 92px 20px 20px 76px;
    height: calc(100vh);
}

.client-body {
    padding: 0px 20px;
    height: calc(100vh);
}

.container-body-secundary-header {
    padding: 195px 20px 20px 76px;
    height: calc(100vh);
}

.white-body {
    background-color: white;
    box-shadow: 0px 2px 6px #00000029;
    padding: 20px 15px;
    display: block;
    width: 100%;
    max-width: 767px;
    margin: auto;
    border-radius: 2px;
}

.title-header {
    border-bottom: 1px solid #C6C6C6;
    margin-bottom: 25px;
}

.title-header h1 {
    display: inline-block;
    font-size: 20px;
    vertical-align: top;
    margin-bottom: 10px;
}

.title-header mat-icon {
    display: inline-block;
    font-size: 29px;
    color: #85C443;
    margin-right: 11px;
}

.title-header mat-slide-toggle {
    height: 30px;
    margin-left: 10px;
}

/*********************************LAYOUT********************************************/


/*********************************CARD LIST********************************************/
.card-list .card-item {
    padding: 10px 9px;
    border: 1px solid #A6A6A629;
    margin-top: 15px;
    border-radius: 4px;
    height: calc(100% - 15px);
}

.card-list .card-item .infos {
    display: inline-block;
    width: calc(100% - 78px);
}

.card-list .card-item .actions {
    display: inline-block;
    width: 78px;
    text-align: right;
    padding-bottom: 4px;
    vertical-align: bottom
}

.card-list .card-item .actions a {
    text-align: center;
    color: #85C443;
    border: 1px solid #85C443;
    border-radius: 50px;
    width: 28px;
    height: 28px;
    display: inline-block;
    margin: 0px 5px;
    cursor: pointer;
    transition: all linear .1s;
}

.card-list .card-item .actions a:hover,
.card-list .card-item .actions a.active {
    background-color: #85C443;
    color: white
}

.card-list .card-item .actions a mat-icon {
    font-size: 17px;
    padding-top: 4px;
}

.card-list .card-item span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.card-list .card-item span.primary {
    font-size: 15px;
    font-weight: 600;
}

.card-list .card-item span.secundary {
    font-size: 13px;
    color: #C6C6C6
}

.card-list .card-item span.secundary-status {
    font-size: 13px;
    color: #85C443;
}

.card-list .card-item span.last-info {
    padding-top: 10px;
}


/*********************************END CARD LIST********************************************/

/*********************************UTILS********************************************/
.vertical-aling-out {
    display: table;
    height: 100%;
    width: 100%;
}

.vertical-aling-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}

.container-out {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .2);
    transition: all linear .2s;
}

.bold {
    font-weight: bold;
}

/*********************************END UTILS********************************************/

@media only screen and (max-width: 1000px) and (min-width: 768px) {
    .container-body-secundary-header {
        padding: 195px 20px 20px 76px;
        height: calc(100vh);
    }
}

@media only screen and (max-width: 767px) {
    .container-body {
        height: unset;
        min-height: calc(100vh);
        padding: 76px 10px 10px 10px;
        background-color: white;
    }

    .container-body-secundary-header {
        height: unset;
        min-height: calc(100vh);
        padding: 210px 10px 66px 10px;
        background-color: white;
    }

    .white-body {
        box-shadow: none;
        padding-left: 0px;
        padding-right: 2px;
    }

    .btn-primary,
    .btn-secundary {
        display: block !important;
        width: 100% !important;
    }

    .back-link {
        display: inline-block;
    }
}


/* Generic table */
.basic-table {
    width: 100%;
}

.basic-table__head {
    border-bottom: 1px solid #a6a6a680;
    color: #85C443;
    font-weight: 600;
    padding-bottom: 1rem;
}

.basic-table__head>tr>th {
    padding-bottom: 0.8rem;
}

.basic-table__head>tr>th:first-child {
    padding-left: 0.5rem;
}

.basic-table__body>tr>td:first-child {
    padding-left: 0.5rem;
}

.basic-table__body {
    color: #6e6e6e;
}

.basic-table__body>tr:hover {
    background-color: #86c4433d;
}

.basic-table__body__row {
    height: 3rem;
    border-bottom: 1px solid #A6A6A629;
}

.basic-table__body__row__action {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    padding-right: 1rem;
    color: #A6A6A629;
}

.basic-table__body__row:hover .basic-table__body__row__action {
    color: #6e6e6e;
}

.basic-table__body__row__action>mat-icon {
    margin: 0px 5px;
}

.mobile .basic-table__body__row__action {
    color: #6e6e6e;
}

/* Generic Accordion */

.accordion {
    display: block;
    padding-bottom: 2rem;
}

.accordion-item {
    display: block;
    border: solid 1px #A6A6A629;
    margin-bottom: 1rem;
    box-shadow: 0px 2px 6px #00000029;
}

.accordion-item+.accordion-item {
    border-top: none;
}

.accordion-item-description {
    font-size: 0.85em;
    color: #999;
}

.accordion-item-description-expanded {
    color: white;
}

.accordion-item-header {
    padding: 1rem;
}

.accordion-item-body {
    padding: 0.5rem;
}

.accordion-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.accordion-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.list-tag {
    display: block;
    margin-bottom: 15px;
}

.list-tag .tag {
    display: inline-block;
    margin-right: 8px;
    background-color: #F8F8F8;
    border-radius: 5px;
    padding: 3px 8px;
    margin-bottom: 8px;
    box-shadow: 0px 1px 1px #00000029;
}

/* Mobile config */

.mobile {
    display: none !important;
}

@media only screen and (max-width: 767px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block !important;
    }
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.padding-0 {
    padding: 0.5rem;
}

.padding-y {
    padding: 0.5rem 0rem;
}

.padding-x {
    padding: 0rem 0.5rem;
}

.padding-right-0 {
    padding-right: 0.5rem;
}

/************ Material Progress Bar *************/
.mat-progress-bar-fill::after {
    background-color: #85C443;
}

.mat-progress-bar-buffer {
    background: #d4e4ce;
}

/************ END - Material Progress Bar *************/
.offline {
    width: 100%;
    height: 1.5rem;
    background-color: #f0ecb5;
    text-align: center;
    color: #777777;
    top: 3.5rem;
    position: fixed;
    z-index: 99;
}

.offline--icon {
    margin-right: 1rem;
    top: 0.15rem;
    position: relative;
    font-size: 20px;
}

/************ Form Field Toggle *************/

.form-field-toggle {
    text-align: left;
    padding-bottom: 20px;
}

.form-field-toggle>mat-slide-toggle {
    display: block;
    margin-bottom: 5px;
    margin-top: 5px;
}

/************ END - Form Field Toggle *************/

.body-search-patient-modal .mat-form-field-subscript-wrapper {
    position: relative;
}


/************ START - Telemedicine *************/

.button-container {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 75%;
}

.section-title {
    border-bottom: 1px solid #D9D9D9;
    font-family: 'Roboto';
    text-align: center;
    font-weight: 700;
    line-height: 24px;
    font-size: 20px;
    width: -webkit-fill-available;
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.section-sub-title {
    font-family: 'Roboto';
    color: #919191;
    text-align: center;
    font-weight: 400;
    line-height: 19.36px;
    font-size: 16px;
}

.container-background {
    display: flex;
    padding-top: 5%;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
}

.forward-button {
    line-height: 16.94px;
    font-family: 'Roboto';
    color: #FFFFFF;
    border-radius: 10px !important;
    font-size: 14px;
    font-weight: 600;
}

.cdk-overlay-container mat-dialog-container {
    width: unset;
}
/************ END - Telemedicine *************/

/*********************************self-triage********************************************/

.body-container {
    display: inline-table;
    width: 100%;
    height: calc(100vh - 70px);
    margin-top: 70px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--colorCodeSelfTriage);
}

.body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.white-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background-color: white;
    box-shadow: 5px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.container-box {
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 5px 4px 5px rgb(0 0 0 / 25%);
    max-width: 90%;
    max-height: 90%;
}

.footer-white-container p {
    margin-top: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 7px;
    line-height: 12px;
    text-align: center;
    color: var(--colorCodeSelfTriage);
}

.button-go,
.button-selection,
.button-back {
    border-radius: 10px !important;
    text-transform: uppercase;
    width: 90% !important;
}

.button-selection {
    /* color: var(--colorCodeSelfTriage) !important; */
    color: white !important;
    border: 1px solid var(--colorCodeSelfTriage) !important;
    background-color: var(--colorCodeSelfTriage) !important;
    height: 45px !important;
    font-weight: bold;
    font-size: 14px;
}

.button-selection:focus,
.button-selection:hover {
    /* background-color: var(--colorCodeSelfTriage) !important; */
    opacity: 0.8;
    color: white !important;
}

.button-back {
    color: #85C443 !important;
    border: 1px solid #85C443 !important;
}

@media (min-width: 680px) {
    .white-container {
        min-width: 510px;
        min-height: 400px;
        max-width: 710px;
    }
}

@media (max-width: 305px) {

    .button-go,
    .button-selection,
    .button-back {
        font-size: 11px;
    }
}

@media (max-width: 680px) {

    .body-container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .white-container {
        width: 90%;
        max-height: 85%;
    }
}

@media (max-height: 600px) {

    .white-container {
        height: 90%;
    }

}